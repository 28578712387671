import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import ReportThunk from '../Redux/actions/messageReports';
import { Link, NavLink } from 'react-router-dom';
import PaginationButtons from '../components/PaginationButtons';
import DataThunk from '../Redux/actions/getApidata';
import { exportToCSV } from '../utils/csv';
import ExportDataTable, { TableColumn } from '../components/datatable';
import formatDateToLongForm from '../utils/Date';
import { FunnelIcon } from '@heroicons/react/24/outline';
import UsersThunk from '../Redux/actions/users';
import AdminEdit from '../components/AdminEdituser';
import DeleteUser from '../components/deletUser';
import IconPlus from '../components/Icon/IconPlus';
import AdminCreateUser from '../components/AdminCreateUser';

function Users() {
    const [expandedMessage, setExpandedMessage] = useState<number | null>(null);
    const [expandedNNumbers, SetexpandedNNumbers] = useState<number | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { data, loading } = useSelector((state: RootState) => state.GetUsers);
    console.log(data);
    const [sortedData, setSortedData] = useState<any>([]);

    useEffect(() => {
        if (data) {
            setSortedData(data);
        }
    }, [data]);

    const [selectedApiSecret, setSelectedApiSecret] = useState('');
    const [statusFilter, SetStatusFilter] = useState('');
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setpageSize] = useState(15);
    const handleSelectChange = (selectedSecret: string) => {
        setSelectedApiSecret(selectedSecret);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams({
            pageNumber: currentPage.toString(),
            pageSize: pageSize.toString(),
            api_key: selectedApiSecret,
            status: statusFilter,
        });

        if (from) {
            queryParams.append('from', from);
        }

        if (to) {
            queryParams.append('to', to);
        }

        dispatch(UsersThunk(queryParams.toString()));
    }, [currentPage, selectedApiSecret, statusFilter, from, to, pageSize]);

    const handleFilter = (idToRemove: any) => {
        const filteredReports = data.filter((report: any) => report.id !== idToRemove);
    };

    const columns: TableColumn<any>[] = [
        {
            title: 'First Name',
            accessor: 'firstName',
            render: (row) => <p>{row.firstName}</p>,
        },
        {
            title: 'LastName',
            accessor: 'lastName',
            render: (row) => <p>{row.lastName}</p>,
        },
        {
            title: 'Role ',
            accessor: 'role',
            render: (row) => <p>{row.role}</p>,
        },

        {
            title: 'created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'created At',
            accessor: 'created_at',
            render: (row) => (
                <p className="flex flex-row gap-2 ">
                    {' '}
                    <AdminEdit user={row} />
                    <DeleteUser user={row} handleFilter={handleFilter} />
                </p>
            ),
        },
    ];
    return (
        <div className=" flex  flex-col        ">
            <div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Users</span>
                            </li>
                        </ul>
                    </div>

                    <AdminCreateUser/>
                </div>
            </div>
            <ExportDataTable columns={columns} data={sortedData ?? []} total={0} currentPage={0} nextPage={0} previousPage={0} lastPage={0} isLoading={loading} filterComponent={undefined} />
        </div>
    );
}

export default Users;
