import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMessage, showSuccessMessage } from '../utils/toast';
import { RootState, AppDispatch } from '../store';
import SendThunk from '../Redux/actions/sendSms';
import TextArea from './Textarea';
import DataThunk from '../Redux/actions/getApidata';
import Smschema from '../Validations/sendSms';
import senderIDThunk from '../Redux/actions/GetSenderIds';
import { CircularProgress } from '@mui/material';
import Input from './Input';
import toast from 'react-hot-toast';
import { Textarea } from '@material-tailwind/react';

function SendMany() {
    const { user, loading: userLoad } = useSelector((state: RootState) => state.user);
    const { data: Api_data, loading } = useSelector((state: RootState) => state.data);
    useEffect(() => {
        dispatch(DataThunk());
    }, []);

    const { data: data, loading: loader } = useSelector((state: RootState) => state.senderId);

    useEffect(() => {
        dispatch(senderIDThunk());
    }, []);

    const [selectedSenderId, setSelectedSenderId] = useState('');
    const [Key, setKey] = useState();
    const [Keydata, setKeyData] = useState<any>();
    const [Keys, setKeys] = useState<any[]>([]);
    const [error, setError] = useState('');
    const [apiKeyError, setApiKeyError] = useState('');
    const [senderError, setSenderError] = useState('');

    useEffect(() => {
        return setKeys(data);
    }, [data]);

    const approvedKeys = Keys.filter((item) => item.approved);
    const handleSelectChange = (selectedItem: any) => {
        setKey(selectedItem.secret);
        setKeyData(selectedItem);
        setApiKeyError('');
    };

    const [message, setMessage] = useState('');

    const [smsCount, setSmsCount] = useState(1);
    const maxMessageLength = 160;

    useEffect(() => {
        const messageLength = message.length;
        const smsCount = Math.ceil(messageLength / maxMessageLength);
        setSmsCount(smsCount > 0 ? smsCount : 1);
    }, [message]);

    const handleMessageChange = (event: any) => {
        const newMessage = event.target.value.replace(/[\r\n]/g, '');
        setMessage(newMessage);

        if (newMessage.length > 0) {
        }
    };

    const { isLoading, errorMessage } = useSelector((state: RootState) => state.send);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(Smschema),
    });
    const dispatch = useDispatch<AppDispatch>();

    const submit = async (data: any) => {
        if (!Key || Key === '') {
            setApiKeyError('No API Key selected');
        }
        if (!selectedSenderId || selectedSenderId === '') {
            setSenderError('No sender Id selected');
        } else {
            try {
                setSenderError('');
                const senderId = selectedSenderId;
                console.log(message);
                const recipients = data.recipients
                    .split(',')
                    .map((phoneNumber: string) => phoneNumber.trim())
                    .map((phoneNumber: string) => `25${phoneNumber}`);

                const formData = { senderId, message, recipients };

                const response = await dispatch(SendThunk({ formData, Key })).then((action: any) => unwrapResult(action));
                reset();

                toast.success('Message sent');
            } catch (e: any) {
                return error;
            }
        }
    };

    return (
        <>
            <div className="m-2">
                <div className="w-full p-2">
                    <p className="text-gray-500 dark:text-gray-400 mb-4 sm:text-lg md:text-1xl lg:text-xl">Send SMS</p>
                    <form className="flex flex-col mt-2" onSubmit={handleSubmit(submit)}>
                        <div className="flex flex-col md:flex-row  mt-4 w-full">
                            <div className="md:w-[50%] my-2 md:my-0  w-full  ">
                                <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                    Select API Key
                                </label>
                                <div className="relative     text-left ">
                                    {Api_data.length === 0 ? (
                                        <p className=" form-input   ">
                                            No ApiKey yet{' '}
                                            <Link to="/dashboard/settings" className="text-primary underline">
                                                Create New
                                            </Link>
                                        </p>
                                    ) : (
                                        <select className="form-select " onChange={(event) => handleSelectChange(Api_data.find((item: any) => item?.secret || '' === event.target.value))}>
                                            <option value="" className="text-gray-600 dark:text-gray-400">
                                                {' '}
                                                Select Key
                                            </option>
                                            {Api_data.map((item: any) => (
                                                <option key={item.id} value={item.secret} className="text-gray-600 dark:text-gray-400">
                                                    {`${item.name} (${item.environment})`}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                {apiKeyError && <p className="text-red-500 text-xs italic">{apiKeyError}</p>}
                            </div>

                            {user.role === 'trusted_developer' ? (
                                <div className="senderndAPI2 ml-2 md:mt-0 w-full mt-4  md:w-[50%] flex flex-col ">
                                    <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                        {' '}
                                        Enter Sender ID
                                    </label>
                                    <Input type="text" className=" form-input" name="customSenderId" placeholder="Enter Sender ID" onChange={(event: any) => setSelectedSenderId(event.target.value)} />
                                    {senderError && <p className="text-red-500 text-xs italic">{senderError}</p>}
                                </div>
                            ) : (
                                <div className=" md:ml-2 my-2  sm:mt-0 w-full md:w-[50%] flex flex-col ">
                                    <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                        {' '}
                                        Select Sender ID
                                    </label>

                                    {Keys.filter((item: any) => item.approved).length === 0 ? (
                                        <p className=" form-input ">
                                            No SenderId yet{' '}
                                            <Link to="/dashboard/settings" className="text-primary underline">
                                                Create New
                                            </Link>
                                        </p>
                                    ) : (
                                        <>
                                            <select id="apiKeySelect" className="form-select " onChange={(event) => setSelectedSenderId(event.target.value)} value={selectedSenderId}>
                                                {Keys.filter((item: any) => item.approved).map((item: any) => (
                                                    <option key={item.id} value={item.secret}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    )}
                                    {senderError && <p className="text-red-500 text-xs italic">{senderError}</p>}
                                </div>
                            )}
                        </div>

                        <div className="my-5">
                            <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                {' '}
                                Numbers separate with (,)
                            </label>

                            <TextArea name="recipients" value="" className="" placeholder="ex. 078xxxxxx,072xxxxxxx" register={{ ...register('recipients') }} errors={errors?.recipients?.message} />
                        </div>
                        <div className="mt-3">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                    Message
                                </label>
                                <div className=" ">
                                    <p className="text-gray-600 dark:text-gray-400">{message.length}/160</p>
                                </div>
                            </div>

                            <textarea className="form-textarea " placeholder="Message" defaultValue="Message" onChange={handleMessageChange} value={message} name="message" required />
                        </div>

                        <button type="submit" className="w-1/3 btn btn-lg my-2 btn-primary" data-testid="submit">
                            {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <span>Send ({smsCount}) sms</span>}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SendMany;
