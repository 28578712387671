import React from "react";
import ReactApexChart from "react-apexcharts";

interface BarChartProps {
  chartData: {
    series: {
      name: string;
      data: number[];
    }[];
    labels: string[];
  };
}

const BarChart: React.FC<BarChartProps> = ({ chartData }) => {
  const options:any = {
    series: chartData.series,
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: chartData.labels, 
    },
  };

  return (
    <div className="bg-white  dark:bg-black p-6 rounded-lg shadow-md">
      <ReactApexChart options={options} series={chartData.series} type="bar" height={350} />
    </div>
  );
};

export default BarChart;
