import { Navigate, useRoutes } from 'react-router-dom';
import Login from '../views/Login';
import Register from '../views/Register';
import AdminLayout from '../components/Layouts/DefaultLayout';
import Dashbord from '../views/Dashbord';
import MessagaReports from '../views/MessagaReports';
import Settings from '../views/Settings';
import Sms from '../views/SendMessages';
import Users from '../views/Users';
import Error404 from '../views/errors/4004Error';

export default function AppRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/login" replace /> },
        { path: '*', element: <Error404 /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },

        {
            element: <AdminLayout />,
            children: [
                { path: 'dashboard', element: <Dashbord /> },
                { path: '/dashboard/messages', element: <MessagaReports /> },

                { path: '/dashboard/send-message/*', element: <Sms /> },
       
                {
                    path: '/dashboard/settings/*',
                    element: <Settings />,
                },
                {
                    path: '/dashboard/users/',
                    element: <Users />,
                },
            ],
        },
    ]);
}
