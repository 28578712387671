import { combineReducers, configureStore } from "@reduxjs/toolkit";
import login, { LoginState, loginSlice } from "../Redux/slices/login";
import user, { UserSlice, UserState } from "../Redux/slices/user";
import api, { ApiState, createApiSlice } from '../Redux/slices/createApi'
import data, { dataState, getApidataSlice } from "../Redux/slices/getApiData";
import send, { sendSlice, sendState } from "../Redux/slices/sendSms";
import register, { registerState } from "../Redux/slices/register";
import dashboard, {dashSlice, dashState} from '../Redux/slices/dashboard';
import report ,{ReportSlice, reportState} from '../Redux/slices/MessageReports'
import edit,{editState, editUserSlice} from '../Redux/slices/updateUser'
import senderId,{SenderIdState, senderIdSlice} from '../Redux/slices/GetSenderId'
import postSenderID,{PostSenderIdState, postSenderIdSlice} from '../Redux/slices/postSenderId'
import editSenderID,{EditSenderIdState, editSenderIdSlice} from '../Redux/slices/editSenderID'
import DeleteSenderId,{DeleteSlice, DeleteState} from "../Redux/slices/DeleteSenderId";
import GetUsers,{UsersSlice, UsersState}  from '../Redux/slices/users'
import DeleteUser,{DeleteUserSlice, DeleteUserState}   from '../Redux/slices/deletUser'
import deletUser from "../Redux/slices/deletUser";
import themeConfigSlice from "./themeConfigSlice";
import AdminCreateUserSlice from "../Redux/slices/adminCreateUser";
   
const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    AdminUser:AdminCreateUserSlice.reducer,
    user: UserSlice.reducer,
    login: loginSlice.reducer,
    api: createApiSlice.reducer,
    data: getApidataSlice.reducer,
    send: sendSlice.reducer,
    register: register.reducer,
    dashboard:dashSlice.reducer,
    report: ReportSlice.reducer,
    edit:editUserSlice.reducer,
    senderId:senderIdSlice.reducer,
    postSenderID:postSenderIdSlice.reducer,
    editSenderID:editSenderIdSlice.reducer,
    deleteSenderId:DeleteSlice.reducer,
    GetUsers:UsersSlice.reducer,
    DeleteUser:DeleteUserSlice.reducer
  

});
export const store = configureStore({
  reducer: rootReducer,

});
export type RootState = {

  login: LoginState;
  user: UserState;
  api: ApiState;
  data: dataState;
  send: sendState;
  register: registerState;
  dashboard: dashState;
  report: reportState;
  edit:editState;
  senderId:SenderIdState;
  postSenderID:PostSenderIdState
  EditSenderID:EditSenderIdState
  deleteSenderId:DeleteState
  GetUsers:UsersState
  DeleteUser:DeleteUserState
};

export type IRootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
