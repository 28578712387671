import { createSlice } from "@reduxjs/toolkit";

import UsersThunk from "../actions/users";
import editUserThunk from "../actions/updateUser";

export interface UsersState {
  data: any[];
  loading: boolean;

  error: boolean;
  errorMessage: string | undefined;
}

export const initialState: UsersState = {
  data: [],

  loading: false,

  error: false,
  errorMessage: undefined,
};

export const UsersSlice = createSlice({
  name: "Api data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UsersThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(UsersThunk.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(UsersThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.error = true;
          state.errorMessage =
            payload?.error?.response?.data?.message || "error";
        } else {
          state.error = false;
          state.data = payload;
        }
      })
      .addCase(editUserThunk.fulfilled, (state, { payload }) => {
        console.log(payload);
        const updatedRecordIndex = state.data.findIndex(
          (record) => record.id === payload.id
        );

        if (updatedRecordIndex !== -1) {
          state.data[updatedRecordIndex] = payload;
        }
      });
  },
});

export default UsersSlice.reducer;
