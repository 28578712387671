import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useLocation } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { UsersIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState, RootState } from '../../store';
import { useEffect, useState } from 'react';
import { toggleSidebar } from '../../store/themeConfigSlice';
import IconCaretsDown from '../Icon/IconCaretsDown';
import IconCaretDown from '../Icon/IconCaretDown';
import IconHome from '../Icon/IconHome';
import IconSettings from '../Icon/IconSettings';
import IconUsers from '../Icon/IconUsers';

const Sidebar = () => {
    const [currentMenu, setCurrentMenu] = useState<string>('');
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const semidark = useSelector((state: IRootState) => state.themeConfig.semidark);
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    const toggleMenu = (value: string) => {
        setCurrentMenu((oldValue) => {
            return oldValue === value ? '' : value;
        });
    };

    const { user } = useSelector((state: RootState) => state.user);

    const navigation = [
        {
            name: 'Dashboard',
            to: '/dashboard',
            icon: IconHome,
            current: location.pathname === '/dashboard',
        },
        {
            name: 'Messages', // Parent item
            to: '/dashboard/messages',
            icon: UsersIcon, // You can change the icon as needed
            current: location.pathname === '/dashboard/messages',
            items: [
                { name: 'Reports', to: '/dashboard/messages' },
                { name: 'Send SMS', to: '/dashboard/send-message' },
            ],
        },
    
    ];

    if (user.role === 'admin' || user.role === 'super-admin') {
        navigation.push({
            name: 'users',
            to: '/dashboard/users',
            icon: IconUsers,
            current: location.pathname === '/dashboard/users',
        });
    }
    navigation.push(  {
        name: 'Settings',
        to: '/dashboard/settings',
        icon: IconSettings,
        current: location.pathname === '/dashboard/settings',
    },)

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
    }, [location]);

    return (
        <div className={'dark'}>
            <nav
                className={`sidebar capitalize fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300`}
            >
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <Link to="/" className="main-logo flex  flex-col items-center shrink-0">
                            <p className="text-2xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light"> S<span className='text-primary'>M</span>S</p>
                        </Link>
                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown className="m-auto rotate-90" />
                        </button>
                    </div>
                    <div className="h-10"></div>
                    <PerfectScrollbar className="h-[calc(100vh)] relative">
                        <ul className="relative  space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <ul>
                                    {navigation.map((item, index) => (
                                        <li key={index} className="nav-item">
                                            {item.items ? ( // Check if item has sub-items
                                                <button
                                                    type="button"
                                                    className={`nav-link group w-full`}
                                                    onClick={() => toggleMenu(item.name)}
                                                >
                                                    <div className="flex items-center">
                                                        <item.icon className="group-hover:!text-primary shrink-0" />
                                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#a3a3a3] dark:group-hover:text-white-dark">{item.name}</span>
                                                    </div>
                                                    <div className={currentMenu !== item.name ? 'rtl:rotate-90 -rotate-90' : ''}>
                                                        <IconCaretDown />
                                                    </div>
                                                </button>
                                            ) : (
                                                <Link to={item.to} className={`group ${item.current ? 'active text-primary' : ''}`}>
                                                    <div className="flex items-center">
                                                        <item.icon className="group-hover:!text-primary shrink-0" />
                                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#a3a3a3] dark:group-hover:text-white-dark">{item.name}</span>
                                                    </div>
                                                </Link>
                                            )}
                                            {item.items && (
                                                <AnimateHeight duration={300} height={currentMenu === item.name ? 'auto' : 0}>
                                                    <ul className="sub-menu text-gray-500">
                                                        {item.items.map((subItem, subIndex) => (
                                                            <li key={subIndex}>
                                                                <Link to={subItem.to}>{subItem.name}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </AnimateHeight>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;
