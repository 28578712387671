import { Tab } from '@headlessui/react';
import { BanknotesIcon, CpuChipIcon, CreditCardIcon, CurrencyBangladeshiIcon, HomeModernIcon, MapPinIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@mantine/core';
import { Fragment, useEffect, useState } from 'react';
import { FaIdBadge, FaKey } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import SenderId from './senderId/senderId';
import IconCaretDown from '../components/Icon/IconCaretDown';
import Dropdown from '../components/dropdown';
import Profile from './Profile';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import ExportDataTable, { TableColumn } from '../components/datatable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import DataThunk from '../Redux/actions/getApidata';
import { FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiSchema from '../Validations/api';
import formatDateToLongForm from '../utils/Date';
import EditSenderId from '../components/EditSenderId';
import DeleteSenderId from '../Redux/slices/DeleteSenderId';
import IconPlus from '../components/Icon/IconPlus';
import ApiThunk from '../Redux/actions/createApi';
import { unwrapResult } from '@reduxjs/toolkit';
import Input from '../components/Input';
import Select from '../components/select';
import TextArea from '../components/Textarea';
import { CircularProgress } from '@mui/material';
import IconClipboardText from '../components/Icon/IconClipboardText';
import IconChecks from '../components/Icon/IconChecks';
import toast from 'react-hot-toast';

export default function AdminReportsSummary() {
    const tabs = [
        {
            icon: FaKey,
            title: 'API key',
            selected: true,
        },
        {
            icon: FaIdBadge,
            title: 'Sender Id',
            selected: true,
        },
        {
            icon: UserIcon,
            title: 'Profile',
            selected: true,
        },
    ];

    const tabItems = [<PersonalInformation />, <SenderId />, <Profile />];
    return (
        <div className="text-gray-700 ">
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="/" className="text-primary hover:underline">
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-500">
                    <span>settings</span>
                </li>
            </ul>
            <div className="panel mt-5" id="icon">
                <div className="mb-5">
                    <Tab.Group>
                        <Tab.List className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                            {tabs.map((item, index) => (
                                <Tab key={index} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={`${
                                                selected ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#39437b] dark:!border-b-black' : 'dark:text-gray-400'
                                            }
                                    dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-primary`}
                                        >
                                            <item.icon className="ltr:mr-2 w-5 rtl:ml-2" />
                                            {item.title}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            {tabItems.map((item, index) => (
                                <Tab.Panel key={index}>
                                    <div className="">{item}</div>
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
}

function PersonalInformation() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Main />}></Route>

                <Route path="/create-key" element={<Create />} />
            </Routes>
        </div>
    );
}

function Main() {
    const dispatch = useDispatch<AppDispatch>();
    const { data: Api_data, loading } = useSelector((state: RootState) => state.data);

    useEffect(() => {
        dispatch(DataThunk());
    }, [dispatch]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ApiSchema),
    });

    const [Keys, setKeys] = useState<any[]>([]);
    const [sortedData, setSortedData] = useState<any[]>([]);
    useEffect(() => {
        setKeys(Api_data);
        setSortedData([...Api_data]);
    }, [Api_data]);

    const getFormattedDate = (dateString: any) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear().toString().substr(-2);
        return `${day}/${month}/${year}`;
    };
    const [copiedKeys, setCopiedKeys] = useState<Record<string, boolean>>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);

    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = sortedData.slice(firstPostIndex, lastPostIndex);

    const columns: TableColumn<any>[] = [
        {
            title: 'Name',
            accessor: 'name',
            render: (row) => <p>{row.name}</p>,
        },
        {
            title: 'Description',
            accessor: 'description',
            render: (row) => <p>{row.description}</p>,
        },
        {
            title: 'Secret',
            accessor: 'secret',
            render: (row) => (
                <p className="whitespace-no-wrap text-gray-600  flex  flex-row   dark:text-gray-400">
                    {row.secret}
                    <span
                        className={`ml-2 cursor-pointer text-primary ${copiedKeys[row.secret] ? 'text-green-500' : ''}`}
                        onClick={() => {
                            navigator.clipboard.writeText(row.secret);
                            setCopiedKeys((prevKeys: any) => ({
                                ...prevKeys,
                                [row.secret]: true,
                            }));
                            setTimeout(() => {
                                setCopiedKeys((prevKeys: any) => ({
                                    ...prevKeys,
                                    [row.secret]: false,
                                }));
                            }, 2000);
                        }}
                    >
                        {copiedKeys[row.secret] ? <IconChecks /> : <IconClipboardText />}
                    </span>
                </p>
            ),
        },
        {
            title: 'created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Environment',
            accessor: 'environment',
            textAlign: 'center',
            render: (row) => (
                <div className="flex gap-2 justify-start">
                    {row.environment === 'dev' ? 
<span className="badge bg-primary">Development</span>: 
<span className="badge bg-success">Production</span>}
                </div>
            ),
        },
    ];
    return (
        <>
            <div className="flex flex-row justify-end  items-center my-2 ">
                <Link to="/dashboard/settings/create-key">
                    <button className="btn btn-primary ">
                        <IconPlus className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
                        New Key
                    </button>
                </Link>
            </div>
            <ExportDataTable columns={columns} data={Api_data ?? []} total={0} currentPage={0} nextPage={0} previousPage={0} lastPage={0} isLoading={loading} filterComponent={undefined} />
        </>
    );
}

function Create() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ApiSchema),
    });

    const dispatch = useDispatch<AppDispatch>();
    const { isLoading } = useSelector((state: RootState) => state.api);

    const submit = async (data: any) => {
        try {
            console.log(data);
            const response = await dispatch(ApiThunk(data)).then((action: any) => unwrapResult(action));

            if (response) {
                toast.success('Key created  successfully');
            }
        } catch (e: any) {
            if (e) {
                toast.error('Error Occurred');
            } else {
                toast.error(e.response.data.message);
            }
        }
    };
    const [selectedEnvironment, setSelectedEnvironment] = useState('');

    const handleChangeEnvironment = (event: any) => {
        setSelectedEnvironment(event.target.value);
    };

    return (
        <div className="  mx-8    p-4 h-fit">
            <div className=" flex flex-row  justify-between h-fit py-3">
                <div className="w-[50%] flex flex-row justify-start items-center ">
                    <p className="text-lg   text-gray-600  dark:text-gray-400 font-semibold cursor-pointer"> Create new API key</p>
                </div>
                <div className=" w-[100%] flex flex-row justify-end text-xl text-gray-500  font-semibold cursor-pointer  ">
                    <NavLink to="/dashboard/settings/" className="  ">
                        <button className="btn btn-primary">All keys</button>
                    </NavLink>
                </div>
            </div>

            <form className="w-full" onSubmit={handleSubmit(submit)}>
                <div className="flex flex-row w-full ">
                    <div className="w-[50%] ">
                        <label htmlFor="" className=" dark:text-gray-400  text-gray-600">
                            {' '}
                            Name
                        </label>
                        <input className="form-input" type="text" placeholder="ex. wedding" {...register('name')} />
                        {errors.name && <p className="text-red-500 text-xs">name is required</p>}
                    </div>

                    <div className="  w-[50%] ml-2 ">
                        <label htmlFor="" className="dark:text-gray-400  text-gray-600 ">
                            {' '}
                            Environment
                        </label>
                        <Select name="environment  " value={selectedEnvironment} onChange={handleChangeEnvironment} register={{ ...register('environment') }}>
                            <option value="">Select environment</option>
                            <option value="prod">Production</option>
                            <option value="dev">Development</option>
                        </Select>

                        {errors && typeof errors === 'object' && 'message' in errors && <p className="text-red-500 text-sm text-decorati">{(errors as unknown as FieldError).message}</p>}
                    </div>
                </div>
                <div className="w-[100%] flex flex-col mt-4">
                    <label htmlFor="" className="text-gray-600 dark:text-gray-400">
                        {' '}
                        Description
                    </label>
                    <textarea  className="form-textarea" placeholder="description"  {...register('description')} />
                    {errors.description && <p className="text-red-500 text-xs">Description is required</p>}
                </div>
            

                <button type="submit" className="btn btn-primary mt-2 " >
                    {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <span>Create Key</span>}
                </button>
            </form>
        </div>
    );
}
