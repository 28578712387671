import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import Input from "../components/Input";
import { unwrapResult } from "@reduxjs/toolkit";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { showErrorMessage, showSuccessMessage } from "../utils/toast";
import "react-toastify/dist/ReactToastify.css";
import senderId from "../Validations/senderId";
import EditSenderIdThunk from "../Redux/actions/editSenderId";
import IconEdit from "./Icon/IconEdit";
import toast from "react-hot-toast";

export default function EditSenderId({ Sender }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
 
  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };
  const [name, setname] = useState("");
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(senderId),
  });

  const Id = Sender.id;
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.api);

  const submit = async (data: any) => {
    try {
      const response = await dispatch(EditSenderIdThunk({ data, Id })).then(
        (action: any) => unwrapResult(action)
      );
      reset;
  
      closeModel();

      toast.success("SenderID Updated");
    } catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className=" dark:bg-[#121c2c] modal   bg-gray-50 rounded-lg shadow-lg  w-full  md:w-[30%]  "
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="px-3  rounded">
          <div className="flex flex-row justify-center items-center">
            <h3 className="p-2 text-gray-600 dark:text-gray-400 text-lg">Edit SenderID</h3>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="form-group">
              <Input
                className=" "
                type="text"
                defaultValue={Sender.name}
                name="name"
                placeholder=" Name "
                register={{ ...register("name") }}
                errors={errors?.name?.message}
              />
            </div>

            <div className="mt-2 flex flex-row ">
              <button
                type="submit"
                className="btn  text-gray-600 dark:text-gray-400  border-primary "
              >
                Save
              </button>
              <button type="button" className="btn btn-outline-danger mx-2 " onClick={closeModel}>
                                                                    Discard
                                                                </button>
            </div>
          </form>
        </div>
      </dialog>
      <button
        onClick={() => openModel()}
        title="edit senderId"
        className="btn btn-sm btn-warning"
      >
        {}
     <IconEdit className="w-4 "/>
      </button>
    </>
  );
}
