import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import PostSenderIdThunk from '../Redux/actions/PostSenderId';

import Input from './Input';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { showErrorMessage, showSuccessMessage } from '../utils/toast';
import 'react-toastify/dist/ReactToastify.css';
import senderId from '../Validations/senderId';
import editUserThunk from '../Redux/actions/updateUser';
import IconDesktop from './Icon/IconDesktop';
import IconEdit from './Icon/IconEdit';
import toast from 'react-hot-toast';

export default function AdminEdit({ user }: any) {
    const dialog = useRef<HTMLDialogElement>(null);
    const closeModel = () => {
        if (dialog.current?.close) dialog.current.close();
    };

    const openModel = () => {
        if (dialog.current?.showModal) dialog.current.showModal();
    };

    const close = (e: React.MouseEvent<HTMLElement>) => {
        const dialogDimensions = dialog.current?.getBoundingClientRect();
        if (e.clientX < dialogDimensions!.left || e.clientX > dialogDimensions!.right || e.clientY < dialogDimensions!.top || e.clientY > dialogDimensions!.bottom) {
            closeModel();
        }
    };

    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');

    useSelector((state: RootState) => state.edit);

    const id = user.id;

    const { handleSubmit } = useForm({});

    const dispatch = useDispatch<AppDispatch>();

    const submit = async () => {
        try {
            const datas = { firstName, lastName };

            const response = await dispatch(editUserThunk({ datas, id })).then((action: any) => unwrapResult(action));
            closeModel();
            toast.success('user Updated successfully');
        } catch (e: any) {
            showErrorMessage(e);
        }
    };

    return (
        <>
            <dialog ref={dialog} className="modal rounded-lg shadow-lg   w-full md:w-[40%] dark:bg-[#121c2c] " onClick={(e) => close(e)}>
                <div className="p-3 rounded">
                    <div className="flex flex-row justify-center items-center">
                        <h3 className="p-2 text-gray-600 dark:text-gray-400 text-lg">Edit User </h3>
                    </div>
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="form-group grid grid-cols-2 gap-2">
                            <input
                                className="form-input text-gray-600 dark:text-gray-400  "
                                type="text"
                                name="name"
                                onChange={(event) => setFirstName(event.target.value)}
                                defaultValue={user.firstName}
                            />
                            <input
                                className="form-input text-gray-600 dark:text-gray-400   "
                                type="text"
                                name="lastName"
                                onChange={(event) => setLastName(event.target.value)}
                                defaultValue={user.lastName}
                            />
                            <input className="form-input text-gray-600 dark:text-gray-400 " type="text" name="Email" placeholder={user.email} />
                            <input className="text-gray-600 dark:text-gray-400  form-input  " type="text" name="role" placeholder={user.role} />
                        </div>

                        <div className="mt-2 flex flex-row ">
                            <button type="submit" className=" btn btn-primary">
                                Save
                            </button>
                            <button type="button" className=" mx-2 btn btn-outline-danger" onClick={closeModel}>
                                Discard
                            </button>
                        </div>
                    </form>
                </div>
            </dialog>
            <button onClick={() => openModel()} title="Add User" className="btn  btn-warning">
                <IconEdit className=" w-4 " />
            </button>
        </>
    );
}
