import * as yup from "yup";

const sendfilesms = yup.object().shape({
  message: yup
    .string()
    

});

export default sendfilesms
  ;
