import * as yup from "yup";

const Smschema = yup.object().shape({
  recipients: yup
    .string()
    .required("Recipients are required")
    .test(
      "isValidRecipients",
      "Invalid recipients format",
      (value) => {
        if (!value) return false;
        const phoneNumbers = value.split(",");

        return phoneNumbers.every((phoneNumber) =>
          /^\d{7,15}$/.test(phoneNumber.trim())
        );
      }
    ),

});

export default Smschema;
