import { ChangeEvent, useState, Fragment, useEffect, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMessage, showSuccessMessage } from '../utils/toast';
import { RootState, AppDispatch } from '../store';
import SendThunk from '../Redux/actions/sendSms';
import sendfilesms from '../Validations/sendfile';
import Input from './Input';
import TextArea from './Textarea';
import { EXCEL_FILE_BASE64 } from '../utils/constant';
import DataThunk from '../Redux/actions/getApidata';
import FileSaver from 'file-saver';
import senderIDThunk from '../Redux/actions/GetSenderIds';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import { Textarea } from '@material-tailwind/react';
import IconDownload from './Icon/IconDownload';

function SendFile() {
    const { user, loading: userLoad } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();

    const [Recipients, setRecipients] = useState();
    const { isLoading } = useSelector((state: RootState) => state.send);
    const [selectedSenderId, setSelectedSenderId] = useState('');
    const [apiKeyError, setApiKeyError] = useState('');
    const [senderError, setSenderError] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(sendfilesms),
    });

    //excel reading
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const XLSX = await import('xlsx');
                readExcelFile(file, XLSX);
            } catch (error) {
                return error;
            }
        }
    };

    const readExcelFile = (file: File, XLSX: any) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result;
            processExcelData(data, XLSX);
        };
        reader.readAsBinaryString(file);
    };

    const processExcelData = (data: any, XLSX: any) => {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const recipientsColumnData = jsonData
            .map((row: any) => row[recipientsColumnIndex])
            .filter((recipient: any) => recipient !== undefined && recipient !== null)
            .map((recipient: any) => `250${recipient}`);

        setRecipients(recipientsColumnData);
    };

    const recipientsColumnIndex = 1;

    const { data: data, loading: loader } = useSelector((state: RootState) => state.senderId);

    useEffect(() => {
        dispatch(senderIDThunk());
    }, []);

    const [Keys, setKeys] = useState<any[]>([]);

    useEffect(() => {
        return setKeys(data);
    }, [data]);
    const [Key, setKey] = useState('');

    const submit = async (data: any) => {
        console.log(Key);
        if (!Key || Key === '') {
            setApiKeyError('No API Key selected');
        }
        if (!selectedSenderId || selectedSenderId === '') {
            setSenderError('No  sender Id selected');
        } else {
            try {
                setSenderError('');
                const senderId = selectedSenderId;

                const recipients = Recipients;
                const formData = { senderId, message, recipients };

                const response = await dispatch(SendThunk({ formData, Key })).then((action: any) => unwrapResult(action));
                reset();

                toast.success('Message sent');
            } catch (e: any) {
                toast.error(e);
            }
        }
    };

    console.log(Key);
    const { data: Api_data, loading } = useSelector((state: RootState) => state.data);

    useEffect(() => {
        dispatch(DataThunk());
    }, []);

    const [Keydata, setKeyData] = useState<any>();

    const handleSelectChange = (selectedItem: any) => {
        setKey(selectedItem.secret);
        setKeyData(selectedItem);
        setApiKeyError('');
    };

    const handleDownload = () => {
        let sliceSize = 1024;
        let byteCharacters = atob(EXCEL_FILE_BASE64);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        FileSaver.saveAs(new Blob(byteArrays, { type: 'application/vnd.ms-excel' }), 'my-excel.xlsx');
    };

    const [message, setMessage] = useState('');
    const [smsCount, setSmsCount] = useState(1);
    const maxMessageLength = 160;
   
    useEffect(() => {
        const messageLength = message.length;
        const smsCount = Math.ceil(messageLength / maxMessageLength);
        setSmsCount(smsCount > 0 ? smsCount : 1);
    }, [message]);

    const handleMessageChange = (event: any) => {
       
        const newMessage = event.target.value.replace(/[\r\n]/g, '');
        setMessage(newMessage);
     
        if (newMessage.length > 0) {
    
        }
    };


    return (
        <>
            <div className="m-2 t">
                <div className="w-full  p-2">
                    <p className="text-gray-500 dark:text-gray-400 mb-4 sm:text-lg md:text-1xl lg:text-xl">Send From Excel File</p>

                    <form className="flex flex-col mt-3" onSubmit={handleSubmit(submit)}>
                        <div className="flex flex-col md:flex-row  mt-4 w-full">
                            <div className="md:w-[50%] w-full ">
                                <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                    Select API Key
                                </label>
                                <div className="relative     text-left ">
                                    {Api_data.length === 0 ? (
                                        <p className=" form-input">
                                            No ApiKey yet{' '}
                                            <Link to="/dashboard/settings" className="text-primary underline">
                                                Create New
                                            </Link>
                                        </p>
                                    ) : (
                                        <select className="form-select" onChange={(event) => handleSelectChange(Api_data.find((item: any) => item?.secret || '' === event.target.value))}>
                                            <option value="" className="text-gray-600 dark:text-gray-400">
                                                {' '}
                                                Select Key
                                            </option>
                                            {Api_data.map((item: any) => (
                                                <option key={item.id} value={item.secret} className="text-gray-600 dark:text-gray-400">
                                                    {`${item.name} (${item.environment})`}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                {apiKeyError && <p className="text-red-500 text-xs italic">{apiKeyError}</p>}
                            </div>

                            {user.role === 'trusted_developer' ? (
                                <div className="senderndAPI2 ml-2 md:mt-0 w-full mt-4   md:w-[50%] flex flex-col ">
                                    <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                        {' '}
                                        Enter Sender ID
                                    </label>
                                    <Input type="text" className=" form-input" name="customSenderId" placeholder="Enter Sender ID" onChange={(event: any) => setSelectedSenderId(event.target.value)} />
                                    {senderError && <p className="text-red-500 text-xs italic">{senderError}</p>}
                                </div>
                            ) : (
                                <div className=" md:ml-2 mt-5  sm:mt-0 w-full md:w-[50%] flex flex-col ">
                                    <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                        {' '}
                                        Select Sender ID
                                    </label>

                                    {Keys.filter((item: any) => item.approved).length === 0 ? (
                                        <p className=" form-input  ">
                                            No SenderId yet{' '}
                                            <Link to="/dashboard/settings" className="text-primary underline">
                                                Create New
                                            </Link>
                                        </p>
                                    ) : (
                                        <>
                                            <select id="apiKeySelect" className="form-select " onChange={(event) => setSelectedSenderId(event.target.value)} value={selectedSenderId}>
                                                {Keys.filter((item: any) => item.approved).map((item: any) => (
                                                    <option key={item.id} value={item.secret}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    )}
                                    {senderError && <p className="text-red-500 text-xs italic">{senderError}</p>}
                                </div>
                            )}
                        </div>

                        <div className=" senderndAPI flex flex-col  md:flex-row  mt-7">
                            <div className="senderndAPI1  w-full  md:w-[50%]">
                                <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                    {' '}
                                    Download template
                                </label>

                                <button type="button" className="btn btn-info w-full"
                                                                    onClick={handleDownload}>
                                  <IconDownload/>
                                </button>
                            
                            </div>

                            <div className="senderndAPI2 w-full  md:mt-0 mt-4 md:w-[50%] ml-2">
                                <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                    {' '}
                                    Excel file
                                </label>
                                
                                <input type="file" className="form-input p-2  " onChange={handleFileChange} />
                            </div>
                        </div>

                        <div className=" mt-7">
                            <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                {' '}
                                Numbers Extracted
                            </label>
                            <TextArea className="" placeholder="No number Found" disabled={true} value="numbers" defaultValue={Recipients} />
                        </div>

                        <div className="mt-7">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-medium">
                                    Message
                                </label>
                                <div className=" ">
                                    <p className="text-gray-600 dark:text-gray-400">{message.length}/160</p>
                                </div>
                            </div>

                            <Textarea className="form-textarea" placeholder="Message" onChange={handleMessageChange} value={message} name="message"  required/>
                        </div>

                        {loader}
                        <button type="submit" className="w-1/3 btn btn-lg sm:text-lg text-xs my-2 btn-primary" data-testid="submit">
                            {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <span>Send ({smsCount}) SMS</span>}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SendFile;
