import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setPageTitle, toggleRTL } from '../store/themeConfigSlice';
import { AppDispatch, IRootState, RootState } from '../store';
import IconMail from '../components/Icon/IconMail';
import jwt_decode from 'jwt-decode';
import IconLockDots from '../components/Icon/IconLockDots';
import Loginsvg from '../assets/images/auth/login.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginThunk from '../Redux/actions/login';
import loginSchema from '../Validations/login';
import { unwrapResult } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';

const Login = () => {
    useEffect(() => {
        dispatch(setPageTitle('Login'));
    });
    const navigate = useNavigate();
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

    const { error, errorMessage, isLoading, access_token } = useSelector((state: RootState) => state.login);

    const Key = localStorage.getItem('Ishema-token');
    useEffect(() => {
        const storedToken = localStorage.getItem('Ishema-token');

        if (!isLoading && !storedToken) {
            navigate('/login');
        } else if (storedToken) {
            try {
                const decodedToken: { exp: number } = jwt_decode(storedToken);

                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    navigate('/login');
                } else if (access_token && Key) {
                    setTimeout(() => navigate('/dashboard'), 1000);
                }
            } catch (error) {
                // Handle decoding error

                navigate('/login');
            }
        }
    }, [isLoading, access_token, Key, navigate]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(loginSchema),
    });
    const dispatch = useDispatch<AppDispatch>();

    const submit = async (data: any) => {
        try {
            const response = await dispatch(LoginThunk(data)).then((action: any) => unwrapResult(action));
            reset();

            toast.success('login successfull');
        } catch (e: any) {
            toast.error(e);
        }
    };

    return (
        <div>
            <div className="relative flex min-h-screen items-center justify-center  px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative flex w-full max-w-[1502px] flex-col justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[758px] lg:flex-row lg:gap-10 xl:gap-0">
                    <div className="relative hidden w-full items-center justify-center bg-gradient-to-r from-blue-500 via-gray-300 to-blue-900 p-5 lg:inline-flex lg:max-w-[835px] xl:-ms-28 ltr:xl:skew-x-[14deg] rtl:xl:skew-x-[-14deg]">
                        <div className="absolute inset-y-0 w-8 from-primary/10 via-transparent to-transparent ltr:-right-10 ltr:bg-gradient-to-r rtl:-left-10 rtl:bg-gradient-to-l xl:w-16 ltr:xl:-right-20 rtl:xl:-left-20"></div>
                        <div className="ltr:xl:-skew-x-[14deg] rtl:xl:skew-x-[14deg]">
                            <Link to="/" className="w-48 block lg:w-72 ms-10 text-center">
                                <div className="text-4xl font-bold text-primary">
                                    <span className="text-black">S</span>
                                    <span className="text-primary">M</span>
                                    <span className="text-black">S</span>
                                </div>
                                <p className="text-sm text-gray-600 mt-2">Send over a million messages in a second</p>
                            </Link>

                            <div className="mt-24 hidden w-full max-w-[430px] lg:block">
                                <img src={Loginsvg} alt="Cover Image" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                        <div className="flex w-full max-w-[440px] items-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <Link to="/" className="w-8 block lg:hidden">
                                <img src="" alt="Logo" className="mx-auto w-10" />
                            </Link>
                        </div>
                        <div className="w-full max-w-[440px] lg:mt-16">
                            <div className="mb-10">
                                <h1 className="text-3xl font-bold uppercase !leading-snug text-primary md:text-4xl">Sign in</h1>
                                <p className="text-base  leading-normal text-white-dark">Enter your email and password to login</p>
                            </div>
                            <form className="space-y-5 dark:text-white" onSubmit={handleSubmit(submit)}>
                                <div>
                                    <label htmlFor="Email " className="font-medium">
                                        Email
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="email" type="email" placeholder="Enter Email" className="form-input ps-10 placeholder:text-white-dark" {...register('email')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                    {errors.email && (
          <p className="text-red-500 text-xs">Email is required</p>
        )}
                                </div>
                                <div>
                                    <label htmlFor="Password" className="font-medium">
                                        Password
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="Password" type="password" placeholder="Enter Password" className="form-input ps-10 placeholder:text-white-dark" {...register('password')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                    {errors.password && <p className="text-red-500 text-xs">Password is required</p>}
                                </div>

                                <button type="submit" className="btn  btn-lg  capitalize w-full btn-primary ">
                                    {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <span>Sign in</span>}
                                </button>
                            </form>

                            <div className="text-center dark:text-white mt-2 capitalize ">
                                Don't have an account ?&nbsp;
                                <Link to="/register" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                    SIGN UP
                                </Link>
                            </div>
                        </div>
                        <p className="absolute bottom-6 w-full text-center dark:text-white">© {new Date().getFullYear()} All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
