import React, { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode'
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Settings from "./Settings";
import Sendsms from "../views/SendMessages";
import { App } from "../components/Barchart";
import DashbordThunk from "../Redux/actions/dashboard";
import MessagaReports from "./MessagaReports";
import { useDispatch, useSelector } from "react-redux";
import Users from './Users'
import Profile from "./Profile";
import { AppDispatch, RootState } from "../store";
import { DashCard } from "../components/DashCard";
import IconUsers from "../components/Icon/IconUsers";
import IconUsersGroup from "../components/Icon/IconUsersGroup";
import IconMail from "../components/Icon/IconMail";
import IconLock from "../components/Icon/IconLock";
import IconChrome from "../components/Icon/IconChrome";
import IconAward from "../components/Icon/IconAward";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { KeyIcon, UsersIcon, WalletIcon } from "@heroicons/react/24/outline";
import { FaUser, FaCalendar, FaQuestionCircle, FaComments, FaList, FaUsers, FaLock, FaWallet, FaKeycdn, FaKey } from "react-icons/fa";
import BarChart from "../components/charts/BarChart";
import { CircularProgress } from "@mui/material";


function Dashbord() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { data, loading } = useSelector((state: RootState) => state.dashboard);
  useEffect(() => {
    dispatch(DashbordThunk());
  }, []);
  const { access_token, isLoading } = useSelector(
    (state: RootState) => state.login
  );


  
  useEffect(() => {
    const storedToken = localStorage.getItem('Ishema-token');

    if (!isLoading && !storedToken && !access_token) {
      navigate("/login");
    } else if (storedToken) {
      try {
        const decodedToken:any = jwt_decode(storedToken);
       
        const currentTime = Date.now() / 1000; 
  
        if (decodedToken.exp < currentTime) {
          
          navigate("/login");
        }
      } catch (error) {
              console.log('Error decoding token:', error);
        navigate("/login");
      }
    }
  }, [isLoading, access_token, navigate]);

  const barChartData = {
    series: [
      {
        name: "Message Balance",
        data: [120, 180, 230, 320, 280, 390, 290,300,360,220,320,200],
      },
      {
        name: "Messages Sent",
        data: [80, 120, 190, 260, 220, 310, 210,340,310,200,140,150],
      },
    ],
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sept","Oct","Nov","Dec"],
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
  };

  return (
    <>
      <div className="flex  flex-col   w-full  ">
     <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="/" className="text-primary hover:underline">
                        Dashboard
                    </Link>
                </li>
			
            </ul>

      <div className=" w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
        {loading || !data ? (
          <>
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p><CircularProgress/></p>
          </>
        ) : (
          <>
         <DashCard
          title="Balance"
          value={data.balance ?? 0}
          icon={<FaWallet />}
          backgroundColor="#FF9800"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="SMS sent "
          value={data.sent?? 0}
          icon={<FaComments />}
          backgroundColor="#00BFA5"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="Keys"
          value={data.api_key?? 0}
          icon={<FaKey />}
          backgroundColor="#2196F3"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="Users"
          value={8}
          icon={<FaUsers />}
          backgroundColor="#9C27B0"
          iconColor="#FFFFFF"
        />
    
          </>
        )}
      </div>

    
        <div className="mt-8">
        <BarChart chartData={barChartData} />
      </div>
      </div>
    
    </>
  );
}



export default Dashbord;
